import { MSO_DISPENSARY_SLUGS } from "constants/dispensary";
import { DispensaryV3 } from "custom-types/DispensariesV3";
import { Dispensary } from "custom-types/Dispensary";
import { Store } from "custom-types/Store";

import { convertToKilometers } from "components/AddToBagModal/internal/utils/distance";

const hasDeliveryFlag = (flags: string[] | undefined) =>
  flags?.includes("delivery");

const hasStorefrontFlag = (flags: string[] | undefined) =>
  flags?.includes("storefront");

// The only way we know for sure if a dispensary has external pickup / delivery
// is if it does not have leafly pickup / delivery but the respective flag is present
const hasExternalDelivery = (dispensary: Store) =>
  !hasLeaflyDelivery(dispensary) && hasDeliveryFlag(dispensary.flags);

export const hasLeaflyDelivery = (dispensary: Store) =>
  !!dispensary.hasDeliveryEnabled && dispensary.retailType !== "clinic";

export const hasLeaflyPickup = (dispensary: Store) =>
  !!dispensary.hasReservationsEnabled && dispensary.retailType !== "clinic";

export const hasAnyDelivery = (dispensary: Store) =>
  hasLeaflyDelivery(dispensary) || hasExternalDelivery(dispensary);

// Is a duplicate of hasLeaflyPickup until we decide how to handle external pickup
export const hasAnyPickup = (dispensary: Store) => hasLeaflyPickup(dispensary);

export const isDeliveryOnly = (dispensary: Store) =>
  hasAnyDelivery(dispensary) &&
  !dispensary.hasReservationsEnabled &&
  !hasStorefrontFlag(dispensary.flags);

export const isInMSOTestGroup = (slug: string) =>
  slug && !!MSO_DISPENSARY_SLUGS.includes(slug);

export const convertDispensaryToV3 = (
  dispensaries: Dispensary[],
): DispensaryV3[] => {
  return dispensaries.map((dispensary) => {
    return {
      ...dispensary,
      deliveryServiceAreaRanges: null,
      distanceKm: Number(convertToKilometers(dispensary.distanceMi)),
      exactDeliveryServiceArea: null,
      flags: [
        ...(dispensary.pickupEnabled ? ["pickup"] : []),
        ...(dispensary.deliveryEnabled ? ["delivery"] : []),
      ],
      merchandisedCampaigns: dispensary.merchandisedCampaigns || [],
      premiumRank: dispensary.premiumRank || null,
      starRating: dispensary.starRating || dispensary.rating,
      tier: dispensary.tier || 0,
    };
  });
};
