import { toKm } from "@leafly-com/web-utils";

import { Coordinates } from "custom-types/Coordinates";

const degreesToRadians = (degrees: number): number => {
  return degrees * (Math.PI / 180);
};

export const getMilesBetween = (
  unit: string,
  locationA?: Coordinates,
  locationB?: Coordinates,
): number => {
  if (!locationA || !locationB) {
    return 0;
  }

  const earthRadisKm = 6371;
  const deltaLat = degreesToRadians(locationA.lat - locationB.lat);
  const deltaLon = degreesToRadians(locationA.lon - locationB.lon);
  // eslint-disable-next-line prettier/prettier -- Prettier does not like the no-mixed-operators eslint rule, but we do :)
  const a = (Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2)) + (Math.cos(degreesToRadians(locationB.lat)) * Math.cos(degreesToRadians(locationA.lat)) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2));
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distanceKm = earthRadisKm * c;

  if (unit === "miles") {
    return distanceKm * 0.621371;
  }

  return distanceKm;
};

export const convertToKilometers = (
  miles?: string | number | null,
): string | null => toKm(miles)?.toFixed(1);
